<template>
  <div class="action-dropdown">
    <BaseSVG
      class="action-dropdow__icon"
      :src="require('@/assets/menu-icon.svg')"
    />
    <div v-if="actions.length" class="action-dropdown__list">
      <ul>
        <li
          v-for="(action, index) in actions"
          :key="`action-${index}`"
          @click="action.action(action.actionParams || null)"
        >
          {{ action.label }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import BaseSVG from "@/components/base/BaseSVG";

export default {
  name: "ActionDropdown",
  props: {
    actions: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  components: {
    BaseSVG,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss">
@import "@/scss/colors";
@import "@/scss/button.scss";

.action-dropdown {
  background-color: $light-gray;
  border-radius: 8px;
  position: relative;
  width: 50px;

  svg {
    fill: white;
    transform: rotate(90deg);
    width: 100%;
  }

  &__list {
    display: none;
    right: 0;
    padding-top: 5px;
    position: absolute;
    top: 100%;
    width: 175px;
    z-index: 9;

    ul {
      background-color: white;
      border: 1px solid $fade-gray;
      border-radius: 8px;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
      padding: 6px;
    }

    li {
      border-bottom: solid 1px $fade-gray;
      cursor: pointer;
      padding: 8px;

      &:hover {
        background-color: $fade-gray;
      }

      &:last-child {
        border: 0;
      }
    }
  }

  &:hover {
    .action-dropdown__list {
      display: block;
    }
  }
}
</style>