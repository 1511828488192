<template>
  <div class="providers-manager">
    <loading :active="loading" />

    <modal
      :active="viewLocationsActive"
      @dismiss="viewLocationsActive = false"
      class="view-locations-modal form-control"
    >
      <template v-if="activeProvider">
        <div class="modal-header">
          <h2>{{ activeProvider.name }} Locations</h2>

          <button class="button" @click="onAddLocation(activeProvider)">
            New Location
          </button>
        </div>

        <div class="form-control">
          <table class="styled-table">
            <tr>
              <th>Name</th>
              <th>Street Address</th>
              <th>Locale</th>
              <th>Phone</th>
              <th>Coords</th>
            </tr>
            <tr
              v-for="location in activeProvider.locations"
              :key="`location-${location.id}`"
              @click="onEditLocation(location)"
            >
              <td>
                <strong>{{ location.name }}</strong>
              </td>
              <td>
                {{ location | streetAddress }}
              </td>
              <td>
                {{ location | locale }}
              </td>
              <td>
                {{ location.phone }}
              </td>
              <td>{{ location | coords }}</td>
            </tr>
          </table>
        </div>
      </template>
    </modal>

    <modal
      :active="viewAudiencesActive"
      @dismiss="viewAudiencesActive = false"
      class="view-audiences-modal form-control"
    >
      <template v-if="activeProvider">
        <div class="modal-header">
          <h2>{{ activeProvider.name }} Audiences</h2>
        </div>

        <div class="form-control">
          <table class="styled-table">
            <tr>
              <th>Name</th>
              <th>Specialty</th>
            </tr>
            <tr
              v-for="audience in activeProvider.audiences"
              :key="`audience-${audience.id}`"
            >
              <td>
                <strong>{{ audience.name }}</strong>
              </td>
              <td>{{ audience.specialty }}</td>
            </tr>
          </table>
        </div>
      </template>
    </modal>

    <modal
      :active="viewCampaignsActive"
      @dismiss="viewCampaignsActive = false"
      class="view-campaigns-modal form-control"
    >
      <template v-if="activeProvider">
        <div class="modal-header">
          <h2>{{ activeProvider.name }} Campaigns</h2>
        </div>

        <div class="form-control">
          <table class="styled-table">
            <tr>
              <th>Name</th>
              <th>Status</th>
            </tr>
            <tr
              v-for="campaign in activeProvider.campaigns"
              :key="`campaign-${campaign.id}`"
              @click="onViewCampaign(campaign)"
            >
              <td>
                <strong>{{ campaign.name }}</strong>
              </td>
              <td>{{ campaign.status }}</td>
            </tr>
          </table>
        </div>
      </template>
    </modal>

    <modal
      :active="updateLocationActive"
      @dismiss="updateLocationActive = false"
      class="update-location-modal form-control"
    >
      <template v-if="activeLocation && activeProvider">
        <h2 v-if="activeLocation.id">Edit {{ activeLocation.name }}</h2>
        <h2 v-else>Add New Location to {{ activeProvider.name }}</h2>

        <div class="row">
          <InputField label="Name" v-model="activeLocation.name" />

          <InputField label="Phone" v-model="activeLocation.phone" />
        </div>

        <div class="row">
          <InputField label="Address" v-model="activeLocation.address" />

          <InputField label="Suite" v-model="activeLocation.suite" />
        </div>

        <div class="row">
          <InputField label="City" v-model="activeLocation.city" />

          <InputField label="State" v-model="activeLocation.state" />

          <InputField label="Zip" v-model="activeLocation.zip_code" />
        </div>

        <button class="button" @click="onSaveLocation">Save</button>
      </template>
    </modal>

    <modal
      :active="updateProviderActive"
      @dismiss="updateProviderActive = false"
      class="update-provider-modal form-control"
    >
      <template v-if="activeProvider">
        <h2 v-if="activeProvider.id">Edit {{ activeProvider.name }}</h2>
        <h2 v-else>Add New Provider</h2>

        <InputField label="Name" v-model="activeProvider.name" />

        <button class="button" @click="onSaveProvider">Save</button>
      </template>
    </modal>

    <div class="hero hero--providers">
      <div class="container">
        <h2>Providers</h2>

        <button class="button" @click="onAddProvider">Add New Provider</button>
      </div>
    </div>

    <div class="container">
      <div class="providers-wrapper card-wrapper">
        <div class="card form-control">
          <table class="styled-table">
            <tr>
              <th>Name</th>
              <th>Locations</th>
              <th>Audiences</th>
              <th>Campaigns</th>
              <th>Actions</th>
            </tr>
            <tr v-for="provider in providers" :key="`provider-${provider.id}`">
              <td>
                <strong>{{ provider.name }}</strong>
              </td>
              <td>
                <div v-if="provider.locations" @click="showLocations(provider)">
                  <Pill
                    :count="`${provider.locations.length} locations`"
                    :label="true"
                  />
                </div>
              </td>
              <td>
                <div v-if="provider.audiences" @click="showAudiences(provider)">
                  <Pill
                    :count="`${provider.audiences.length} audiences`"
                    :label="true"
                  />
                </div>
              </td>
              <td>
                <div v-if="provider.campaigns" @click="showCampaigns(provider)">
                  <Pill
                    :count="`${provider.campaigns.length} campaigns`"
                    :label="true"
                  />
                </div>
              </td>
              <td>
                <ActionDropdown :actions="getActions(provider)" />
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
import Loading from "@/components/Loading";
import Pill from "@/components/Pill";
import ActionDropdown from "@/components/ActionDropdown";
import InputField from "@/components/fields/InputField";
import Location from "@/models/Location";
import Provider from "@/models/Provider";

export default {
  name: "Providers",
  components: {
    Loading,
    Pill,
    Modal,
    ActionDropdown,
    InputField,
  },
  filters: {
    coords(value) {
      if (value.lat && value.lng) {
        return `${value.lat}, ${value.lng}`;
      }

      return "";
    },
    locale(value) {
      if (value.city) {
        return `${value.city}, ${value.state} ${value.zip_code}`;
      }

      return "";
    },
    streetAddress(value) {
      let address = value.address;
      if (value.suite) {
        address = `${address} ${value.suite}`;
      }

      return address;
    },
  },
  data() {
    return {
      activeLocation: null,
      activeProvider: null,
      viewAudiencesActive: false,
      viewCampaignsActive: false,
      viewLocationsActive: false,
      updateLocationActive: false,
      updateProviderActive: false,
    };
  },
  computed: {
    loading() {
      return this.$store.getters["providers/loading"];
    },
    providers() {
      return this.$store.getters["providers/providers"];
    },
    providerList() {
      return this.providers.map(function (provider) {
        return provider.campaigns;
      });
    },
  },
  methods: {
    showAudiences(provider) {
      this.activeProvider = provider;
      this.viewAudiencesActive = true;
    },
    showCampaigns(provider) {
      this.activeProvider = provider;
      this.viewCampaignsActive = true;
    },
    showLocations(provider) {
      this.activeProvider = provider;
      this.viewLocationsActive = true;
    },
    onAddLocation(provider) {
      this.activeProvider = provider;
      this.viewLocationsActive = false;

      this.activeLocation = new Location();
      this.updateLocationActive = true;
    },
    onEditLocation(location) {
      this.viewLocationsActive = false;
      this.activeLocation = location;
      this.updateLocationActive = true;
    },
    onCreateLocation() {
      this.$store
        .dispatch("providers/addLocation", this.activeLocation)
        .then(() => {
          this.getProviders();
        });
    },
    onSaveLocation() {
      this.activeLocation.provider_id = this.activeProvider.id;

      if (!this.activeLocation.id) {
        this.onCreateLocation();
        return;
      }

      this.$store
        .dispatch("providers/saveLocation", this.activeLocation)
        .then(() => {
          this.getProviders();
        });
    },
    onAddProvider() {
      this.activeProvider = new Provider();
      console.log(this.activeProvider);
      this.updateProviderActive = true;
    },
    onEditProvider(provider) {
      this.activeProvider = provider;
      this.updateProviderActive = true;
    },
    onCreateProvider() {
      this.$store
        .dispatch("providers/addProvider", this.activeProvider)
        .then(() => {
          this.getProviders();
        });
    },
    onSaveProvider() {
      if (!this.activeProvider.id) {
        this.onCreateProvider();
        return;
      }

      this.$store
        .dispatch("providers/saveProvider", this.activeProvider)
        .then(() => {
          this.getProviders();
        });
    },
    getActions(provider) {
      const actions = [
        {
          action: this.onAddLocation,
          actionParams: provider,
          label: "Add Location",
        },
        {
          action: this.onEditProvider,
          actionParams: provider,
          label: "Update Provider",
        },
        {
          action: this.showAudiences,
          actionParams: provider,
          label: "View Audiences",
        },
      ];

      return actions;
    },
    getProviders() {
      this.activeProvider = null;
      this.viewLocationsActive = false;
      this.updateLocationActive = false;
      this.updateProviderActive = false;

      this.$store.dispatch("providers/getProviders");
    },
    onViewCampaign(campaign) {
      let routeData = this.$router.resolve({
        name: "campaign",
        params: { id: campaign.id },
      });
      window.open(routeData.href, "_blank");
    },
  },
  mounted() {
    if (!this.providers.length) {
      this.getProviders();
    }
  },
};
</script>

<style lang="scss">
@import "@/scss/colors";
@import "@/scss/button";
@import "@/scss/forms";
@import "@/scss/table";
@import "@/scss/hero";

.hero--providers {
  .container {
    justify-content: space-between;

    .button {
      margin-left: 0 !important;
    }
  }
}

.providers-manager {
  .modal-content {
    width: 960px;
  }

  .form-control {
    h2 {
      font-weight: bold;
    }
  }

  .pill {
    cursor: pointer;
  }

  .styled-table {
    tr {
      &:last-of-type {
        border-bottom: none;
      }

      &:hover {
        background-color: #f6f6f6;

        &:first-child {
          background-color: #fff;
        }
      }
    }

    td {
      padding-bottom: 8px;
      padding-top: 8px;
      vertical-align: middle;

      strong {
        color: $light-blue;
        display: block;
        font-size: 16px;
        margin-bottom: 4px;
      }
    }
  }
}
</style>