function Location() {
  return {
    name: null,
    address: null,
    suite: null,
    city: null,
    state: null,
    zip: null,
    phone: null,
  };
}

export default Location;
